/* base */
@import 'functions';
@import 'vars';
@import 'fonts';
@import 'typography';
@import 'breakpoints';
@import 'base';

.active-contact {
    overflow-y: scroll !important;
}

.MuiDialog-container {
    padding-top: getRem(-70);
    height: getRem(600) !important;
    outline: 0;

    .MuiTypography-h3 {
        font-size: getRem(28);
    }

    @include breakpoint(desktop) {
        padding-top: getRem(100) !important;
        height: getRem(700) !important;
        outline: 0;

        .MuiTypography-h3 {
            font-size: getRem(35) !important;
        }
    }


}

a {
    transition: .3s;
}

.MuiPickersToolbar-toolbar,
.MuiPickerDTTabs-tabs,
.MuiPickersDay-daySelected,
.MuiPickersClockPointer-pointer,
.MuiPickersClock-pin {
    background-color: #161A23 !important;
}

.MuiPickersClockPointer-thumb {
    border: 14px solid #161A23 !important
}

.MuiFilledInput-underline:after {
    border-bottom: getRem(2) solid #B45C45 !important;
}

.MuiFilledInput-underline:after,
.MuiInput-underline:after {
    border-bottom: getRem(2) solid #B45C45 !important;
}


.MuiFormLabel-root.Mui-focused {
    color: #B45C45 !important
}

.MuiFormLabel-root,
.MuiInputBase-root {
    font-family: "Montserrat-Light" !important;
    font-weight: 500 !important;
    font-size: getRem(18) !important;
    color: white !important;

}

.MuiFilledInput-root {
    background-color: rgb(145 145 145 / 9%) !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

}

.MuiDialogContent-root {
    overflow-y: hidden !important;
}

.MuiFormControl-root {
    width: 100%;
}

.MuiFormControl-marginNormal {
    margin-top: getRem(8) !important;
    margin-bottom: getRem(8) !important;
}

.MuiInput-underline:before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: getRem(1) solid rgba(0, 0, 0, 0.42) !important;
}

.MuiFilledInput-multiline {
    padding: getRem(27) getRem(12) getRem(10) !important;
}

.body,
html {
    transition: all .15s linear !important;
}
body {
    padding-right: 0 !important;
}
.MuiFilledInput-input {
    padding: getRem(27) getRem(12) getRem(10);
}

.MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(0.75rem, 0.625rem) scale(0.75) !important;
}

.MuiInputLabel-filled {
    transform: translate(0.75rem, 1.25rem) scale(1) !important;
}

.react-toggle-track {
    width: getRem(50) !important;
    height: getRem(24) !important;
    border-radius: getRem(30) !important;
}

.react-toggle--checked .react-toggle-thumb {
    left: getRem(27) !important;
}

.react-toggle-track-check {
    width: getRem(14) !important;
    height: getRem(10) !important;
    left: getRem(8) !important;
}

.react-toggle-screenreader-only {
    height: getRem(1) !important;
    margin: getRem(-1) !important;
    width: getRem(1) !important;
}

.react-toggle-thumb {
    top: getRem(1) !important;
    left: getRem(1) !important;
    width: getRem(22) !important;
    height: getRem(22) !important;
    border: getRem(1) solid #4D4D4D !important;
}

.react-toggle-track-x {
    width: getRem(10) !important;
    height: getRem(10) !important;
    right: getRem(10) !important;
}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input {
    padding: getRem(27) getRem(12) getRem(10);

}
.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    
    min-width: getRem(40) !important;
}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-endAdornment {
    right: getRem(9) !important;
}
.MuiAutocomplete-endAdornment {
    top: calc(50% - getRem(14))!important;

}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
    padding-top: getRem(19) !important;
    padding-left: getRem(8) !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
    padding-right: getRem(65) !important;
}
.MuiAutocomplete-clearIndicator {
    padding: getRem(4)!important;
    visibility: hidden;
    color: aliceblue!important;
    margin-right: getRem(-2)!important;
}
.MuiAutocomplete-popupIndicator {
    padding: getRem(4)!important;

    color: aliceblue!important;
    margin-right: getRem(-2)!important;
}
.MuiFilledInput-adornedEnd {
    padding-right: getRem(12)!important;
}
.MuiAutocomplete-popper {
            
    .MuiAutocomplete-option {
      
            background-color: rgb(255, 255, 255)!important;
     
    }
}
.MuiAutocomplete-option[data-focus="true"] {
    background-color: rgb(223, 223, 223)!important;
    color: #B45C45!important;
}
.MuiAutocomplete-option{
    color: #414141!important;
}
.MuiFilledInput-underline.Mui-error:after {
    transform: scaleX(1);
    border-bottom-color: #f44336!important;
}

.globalBtn {
    cursor: pointer;
    text-decoration: none;
    font-size: getRem(20);
    color: white;
    font-weight: 100;
    border-radius: getRem(4);
    border: getRem(1) solid #B45C45;
    padding: getRem(10) getRem(20);
    background: linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;
    &:hover{
        background: linear-gradient(306.29deg, #ca674e 0%, #ff9c75 142.59%);
        border: getRem(1) solid #B45C45;
    }
}
.gif-full{
    width: 100%;
}
:root {
    --toastify-toast-width: getRem(420)!important;
  
    --toastify-toast-background: rgb(7, 5, 124);
}
.Toastify__toast-theme--light {
    background: #18366c!important;
    color: #e97d62!important;
}
.Toastify__toast-body {
    padding: getRem(6);
}
.Toastify__toast-container {
    padding: getRem(4);
}
.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input {
    padding: getRem(9) getRem(4)!important;
}
.image-gallery-bullets .image-gallery-bullet:hover {
    background: #18366c !important;
    border: 1px solid #B45C45;
}
.home-solving .image-gallery .image-gallery-slide .image-gallery-image{

    max-height: 29rem!important;
}
.globalLink{
    color: #B45C45!important;
    text-decoration: none;
    &:hover{
        color: #cf7058!important;
    }
}