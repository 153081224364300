@import "../../styles/index.scss";

.video-box {
    .video-react.video-react-fluid,
    .video-react.video-react-16-9,
    .video-react.video-react-4-3 {
        height: 100%;
    }

    & .video-react-loading-spinner {
        display: none !important;
    }
    & .video-react-button{
        display: none!important;
    }
    .video-react-big-play-button{
        display: none!important;
    }
    video, .video-react-poster{
        object-fit: cover!important;
    }
    button{
        display: none!important;
    }
    .video-react .video-react-big-play-button{
        display: none!important;
    }
    .video-react .video-react-poster{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}