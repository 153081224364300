@import "../../styles/index.scss";

.changePass {
    z-index: 1000;
    color: white;
    background: url('https://cdn.atlasmagnetics.com/qualityBackground.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    @include breakpoint(desktop) {
        padding: getRem(100) getRem(350) getRem(120);
    }

    .mobile-view {
        display: flex !important;

        @include breakpoint(desktop) {

            display: none !important;
        }
    }

    &-container {
        border-radius: 1px;
        background: var(--midnight-blur, rgba(22, 26, 35, 0.60));
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);

        @include breakpoint(desktop) {
            display: flex;
        }

        padding: getRem(84) getRem(20) getRem(20) getRem(20)!important;

        @include breakpoint(desktop) {
            padding: getRem(40) getRem(110) getRem(120)!important;
        }

        &-left {
            max-width: getRem(300);

            .logout {
                display: none !important;

                @include breakpoint(desktop) {
                    display: flex !important;
                }
            }

            &-info {
                margin-bottom: getRem(20);
                display: flex;

                @include breakpoint(desktop) {
                    display: block;
                    margin-bottom: getRem(60);
                }

                &-avatar {
                    width: getRem(64)!important;
                    height: getRem(64)!important;

                    @include breakpoint(desktop) {
                        width: getRem(140)!important;
                        height: getRem(140)!important;
                    }

                }

                &-block {
                    margin-top: getRem(-10);
                    margin-left: getRem(20);

                    @include breakpoint(desktop) {
                        margin-top: 0;
                        margin-left: 0;
                    }
                }

                &-name {
                    font-size: getRem(20);

                    @include breakpoint(desktop) {
                        font-size: getRem(36);
                    }

                    width: getRem(300);
                    font-family: "Montserrat-Light";

                }

                &-email {
                    font-size: getRem(15);

                    font-family: "Montserrat-Light";
                    font-family: 300 !important;
                }


            }

            &-list {
                padding: 0;

                img {
                    height: getRem(32);
                    width: getRem(32);
                    margin-right: getRem(30);
                }

                li {
                    display: flex;
                    align-items: center;
                    font-size: getRem(20);
                    margin-bottom: getRem(25);
                }

                &-text {
                    margin-left: getRem(-20) !important;
                    font-size: getRem(15) !important;
                    font-weight: 100;
                    font-family: "Montserrat-Light";
                    max-width: getRem(200);

                    a {
                        text-decoration: none;
                        color: aliceblue;

                        &:hover {
                            color: rgb(214, 214, 214);
                        }
                    }

                    &-margin {

                        @include breakpoint(desktop) {
                            margin-top: getRem(70);
                        }
                    }
                }

            }
        }



        &-main {
            &-changePass {
                @include breakpoint(desktop) {
                    padding: getRem(50) getRem(0) getRem(0) getRem(140);
                    width: getRem(380);
                }


                input {
                    width: 100%
                }
            }


        }
    }
}