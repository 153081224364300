@import '../../styles/index.scss';

.header {
    width: 100vw;

    width: -moz-available;         
    width: -webkit-fill-available; 
    width: fill-available;
    position: fixed !important; 
    z-index: 998;
    height: getRem(72);
    background: #161A23;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:getRem(215);
    
    
    img {
        width: getRem(134);
        height: getRem(56);
    }

    &-links {
        display: flex;
        gap: getRem(56);

        a,
        span {
            cursor: pointer;
            text-decoration: none;
            font-size: getRem(20);
            color: white;

            &:hover {
                color: #B45C45;
            }
        }

        &-dropdown {
            padding: getRem(16) getRem(12);
            margin-top: getRem(24);
            gap: getRem(16);
            display: flex;
            flex-direction: column;
            position: absolute;
            background: linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);

            a {
                &:hover {
                    color: #18366C;
                }
            }
        }
    }
    &-login-btn{
        background: linear-gradient(306.29deg, #1c212c 0%, #18366C 142.59%)!important;
        border: 0.0625rem solid #18366C!important;
        margin-left: getRem(-100);
        margin-right: getRem(-190);
        &:hover {
            background: linear-gradient(306.29deg, #2d3546 0%, #214891 142.59%)!important;
        }
    }
    &-contact {
        cursor: pointer;
        text-decoration: none;
        font-size: getRem(20);
        color: white;
        font-weight: 700;
        border-radius: getRem(4);
        border: getRem(1) solid #B45C45;
        padding: getRem(10) getRem(20);
        background: linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
        -webkit-transition: all 500ms;
        -moz-transition: all 500ms;
        -o-transition: all 500ms;
        transition: all 500ms;
        &:hover{
            background: transparent;
            border: getRem(1) solid #B45C45;
        }
    }
    &-profile{
        img{
            height: getRem(32);
            width: getRem(32);
        }
        margin-right: getRem(-180);
    }
}

.header-mobile {
    position: fixed !important; 
    width: 100%;
    z-index: 100;
    
    &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: getRem(8) getRem(24);
        background: #161A23;
        a {
            img {
                height: getRem(42);
            }
        }

        img {
            height: getRem(36);
        }
    }
    .active-nav{
        overflow-y: scroll;
        
    }
    .dissable-scroll{
        overflow-y: hidden;
        
    }
    &-nav {
        z-index: 999;
        width: 100%;
        position: absolute;
        background: rgba(24, 54, 108, 0.5);
        backdrop-filter: blur(7px);
        display: flex;
        flex-direction: column;
        gap: getRem(32);
        align-items: flex-end;
        padding-top: getRem(42);
        height: 100vh;
        
        &-dropdown {
            
            display: flex;
            flex-direction: column;
            gap: getRem(9);
            align-items: flex-end;
        }

        button {
            margin-right: getRem(24);
            margin-bottom: getRem(500);
        }

        a,
        span {
            margin-right: getRem(24);
            cursor: pointer;
            text-decoration: none;
            font-size: getRem(24);
            color: white;

            &:hover {
                color: #B45C45;
            }
        }
    }
    .contact-container{
        height: 100vh;
    }
}