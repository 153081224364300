@import '../../styles/index.scss';

.policy {
    &-description {
        background-color: #fff;
        padding: getRem(128) getRem(24) getRem(72);
        h4 {
            margin: 0;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: getRem(36);

        }

        hr {
            margin-bottom: getRem(40);
        }
        a {
            text-decoration: none;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        @include breakpoint(desktop) {
            padding: getRem(100) getRem(350) getRem(72);
        }

        h5 {
            margin: 0;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: getRem(28);
            @include breakpoint(desktop) {
                
                font-size: getRem(32);
            }
        }

        h6 {
            margin: 0;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: getRem(24);
            @include breakpoint(desktop) {
                
                font-size: getRem(28);
            }
        }

        h4 {
            margin: 0;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: getRem(32);

            @include breakpoint(desktop) {
                font-size: getRem(36);
                margin-top: getRem(83);
            }
        }
        p {
            text-align: justify;
            color: black;
            font-size: getRem(15);
            font-weight: 400;
            line-height: 180%;
            font-family: "Montserrat-Light";
            text-align: justify;
            @include breakpoint(desktop) {
                font-size: getRem(20);
                font-family: "Montserrat";
            }


        }
    }
}