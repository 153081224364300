@import '../../styles/index.scss';

.map {
	height: getRem(1200);
	background-image: url(https://cdn.atlasmagnetics.com/qualityBackground.png);
	background-size: cover;
	padding: 0;

	&-about {
		img {
			padding: getRem(175) getRem(50) getRem(10) getRem(90);
			height: getRem(850);
			width: getRem(1700);
		}

		&-header {
			position: absolute;
			background: linear-gradient(306.29deg, #b45c45 0%, #ffa886 142.59%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: getRem(36);
			text-align: right;
			right: getRem(340);
			padding-top: getRem(50);
		}

		// .map-block-item {
		// 	&:hover {
		// 		& ~ .map-block-item {
		// 			display: none;
		// 		}
		// 	}
		// }
		.map-block {
			position: relative;
			&-item {
				position: absolute;
				&:hover {
					.map-block-item-content > img {
						display: block;
					}
				}
				&-content {
					display: flex;
					flex-direction: column;
					gap: getRem(3);
					padding: getRem(7) getRem(16);
					width: fit-content;
					height: fit-content;
					background: rgba(22, 26, 35, 0.6);
					backdrop-filter: blur(10px);
					border-radius: 1px;
					> img {
						display: none;
						height: getRem(190);
						width: getRem(230);
						padding: 0;
					}
					h4 {
						margin: 0;
						font-weight: 500;
						font-size: getRem(15);
						color: #fff;
					}
				}
			}
		}
	}
	&-quality {
		img {
			padding: getRem(175) getRem(50) getRem(94);
			height: getRem(930);
			width: getRem(1720);
		}

		&-header {
			position: absolute;
			background: linear-gradient(306.29deg, #b45c45 0%, #ffa886 142.59%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: getRem(36);
			text-align: right;
			right: getRem(340);
			padding-top: getRem(50);
		}

		&-north-america {
			img {
				position: absolute;
				width: getRem(950);
				height: getRem(480);
				left: getRem(62);
			}
		}
		&-europe {
			img {
				position: absolute;
				width: getRem(860);
				height: getRem(640);
				padding-top: getRem(430);
				left: getRem(417);
			}
		}
		&-asia {
			img {
				position: absolute;
				width: getRem(670);
				height: getRem(500);
				left: getRem(920);
			}
		}
	}
}
