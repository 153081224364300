@import "../../styles/index.scss";

.reset{
    z-index: 1000;
    color: white;
    background: url('https://cdn.atlasmagnetics.com/qualityBackground.png') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    @include breakpoint(desktop) {
        padding: getRem(190) getRem(700) getRem(120);
    }

    &-container {
        border-radius: 1px;
        background: var(--midnight-blur, rgba(22, 26, 35, 0.60));
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
        @include breakpoint(desktop) {
        display: flex;
        }
        &-loginForm{
            padding: getRem(64) getRem(20)  getRem(50) getRem(20);
            @include breakpoint(desktop) {
            padding: getRem(32) getRem(50)  getRem(10) getRem(50);
            width: 100%
            }
            h1{
                font-size: getRem(32);
                font-family: "Montserrat";
                font-weight: 400;
            }
            p{
                font-size: getRem(18);
                margin-top: getRem(10);
                font-family: "Montserrat-Light";
                font-weight: 100;
                display: flex;
                @include breakpoint(desktop) {
                    margin-top: getRem(54);
                }
                
            }
            h5{
                font-size: getRem(10);
                font-family: "Montserrat-Light";
                font-weight: 100;
                color: #FFF;
                padding-top: getRem(30);
                a {
                    text-decoration: none;
                    color: #ca674e;
                    &:hover {
                        color: #B45C45;
                    }
                }
            }
            &-passEye {
                font-size:  getRem(22);
                cursor: pointer;
                
               
            }
            &-btn{
                text-align: center;
                margin-top: getRem(50);
                font-family: "Montserrat-Light";
            }
            &-forgot {
                margin-top: getRem(2);
                font-family: "Montserrat-Light";
                font-size: getRem(15);
                font-weight: 100;
            }
            &-reg{
                font-family: "Montserrat-Light";
                font-size: getRem(15);  
                font-weight: 100;
                margin-top: getRem(13);
                a {
                    text-decoration: none!important;
                    span{
                        color: #ca674e;
                        
                    }
                    &:hover {
                        span{
                            color: #eb785c;
                            
                        }
                    }
                }

            }
            &-help{
                padding-top: getRem(28);
                margin-bottom: getRem(-28);
            }

        }
    }
    &-btnRegister{
        font-size: getRem(18)!important;
        margin-left: getRem(80);
        margin-top: getRem(-8);
    }
}