@import '../../styles/index.scss';

.quality {

    &-header {
        background-image: url('https://cdn.atlasmagnetics.com/quality.png');
        background-position: 95% 0%;
        background-size: cover;
        height: getRem(392);
        font-size: getRem(28);
        color: #FFFFFF;
        text-align: end;
        @include breakpoint(desktop) {
            height: getRem(440);
            font-size: getRem(48);
        }



        h2 {
            right: getRem(24);
            position: absolute;
            margin-top: getRem(137);

            @include breakpoint(desktop) {
                right: getRem(350);
                margin-top: getRem(192);
            }
        }
    }
    &-description {
        background-color: #fff;
        padding: getRem(48) getRem(24) getRem(72);

        @include breakpoint(desktop) {
            padding: getRem(64) getRem(350) getRem(72);
        }

        h4 {
            margin: 0;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: getRem(24);
            margin-bottom: getRem(40);

            @include breakpoint(desktop) {
                font-size: getRem(36);
            }
        }
        p {
            margin-top: 0;
            font-size: getRem(15);
            font-weight: 400;
            line-height: 180%;
            font-family: "Montserrat-Light";
            text-align: justify;
            @include breakpoint(desktop) {
                font-size: getRem(20);
                text-align: left;
                font-family: "Montserrat";
            }
        }
        &-first {
            margin-bottom: getRem(96);
        }
    }


    
    &-images {
        background-image: url('https://cdn.atlasmagnetics.com/qualityBackground.png');
        background-size: cover;
        padding: getRem(48) getRem(24) getRem(72);
        img {
            display: none;
        }
        @include breakpoint(desktop) {
            display: flex;
            gap: getRem(60);
            padding: getRem(56) getRem(350) getRem(80);
            align-items: center;

            img {
                width: getRem(583);
                height: 100%;
                display: inline;
            }
        }
        &-text {
            h4 {
                text-align: start;
                margin: 0;
                background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: getRem(24);

                @include breakpoint(desktop) {
                    font-size: getRem(36);
                    text-align: start;
                }
            }
            p {
                text-align: justify;
                color: white;
                font-size: getRem(15);
                font-weight: 400;
                line-height: 180%;
                font-family: "Montserrat-Light";
                text-align: justify;
                @include breakpoint(desktop) {
                    font-size: getRem(20);
                    font-family: "Montserrat";
                }
            }
            img{
                display: inline;
                margin-top: getRem(24);
                width: calc(100% + getRem(48));
                margin-left: getRem(-24);
                @include breakpoint(desktop) {
                    display: none;
                } 
            }
        }
       
    }
    &-supply {
        background: white;

        
        padding: getRem(48) getRem(24) getRem(72);
        
        img {
            width: calc(100% + getRem(48));
            margin-left: getRem(-24);
            display: none;
        }
        
        @include breakpoint(desktop) {
            padding: getRem(64) getRem(350) getRem(80);
            display: flex;
            gap: getRem(59);
            img {
                margin: getRem(140) getRem(0) getRem(110);
                display: inline;
                width: getRem(560);
                margin-left: 0;
            }
        }
        &-text {
            h4 {
                margin: 0;
                
                background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: getRem(24);

                @include breakpoint(desktop) {
                    font-size: getRem(36);
                    margin-top: getRem(24);
                }
            }
            p {
                text-align: justify;
                color: black;
                font-size: getRem(15);
                font-weight: 400;
                line-height: 180%;
                font-family: "Montserrat-Light";
                text-align: justify;
                @include breakpoint(desktop) {
                    font-size: getRem(20);
                    font-family: "Montserrat";
                }


            }
            img{
                display: inline;
                margin-top: getRem(24);
                @include breakpoint(desktop) {
                    display: none;
                    margin-top: 0;
                } 
            }
        }
        &-cert {
            h4 {
                text-align: start;
                margin: 0;
                background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: getRem(24);

                @include breakpoint(desktop) {
                    font-size: getRem(36);
                    text-align: start;
                }

            }
            img {
                display: inline!important;
                max-width: getRem(320)!important;
                width: getRem(320);
                margin-top: 0;
                margin-left: getRem(-20);
            }
        }
    }
}

