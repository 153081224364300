@import "../../styles/index.scss";

.footer {
    &-container {
        background: #161A23;
        position: relative;
        font-family: "Montserrat-Light";
        color: white;
        padding: 0 getRem(24);
        @include breakpoint(desktop) {
            height: getRem(496);
            padding: 0 getRem(350) 0 getRem(350);
        }



        &-links {
            display: block;
            @include breakpoint(desktop) {
                display: flex;
            }

            &-products {
                img {
                    padding-top: getRem(64);
                }

                @include breakpoint(desktop) {
                    padding-top: getRem(128);

                    img {
                        display: none;
                    }
                }

                span {
                    display: none;

                    @include breakpoint(desktop) {
                        font-size: getRem(32);
                        font-family: "Montserrat-Medium";
                        display: inline;
                    }

                }


                p {
                    margin-top: getRem(24);
                    line-height: 2;
                    font-size: getRem(15);
                    font-weight: 100;

                    @include breakpoint(desktop) {
                        font-size: getRem(20);
                    }


                    a {
                        text-decoration: none;
                        color: white;

                        &:hover {
                            color: #B45C45;
                        }
                    }
                }
            }

            &-additional {
                @include breakpoint(desktop) {
                    padding-top: getRem(92);
                }


                img {
                    display: none;

                    @include breakpoint(desktop) {
                        height: getRem(72);
                        width: getRem(183);
                        margin: 0;
                        display: inline;
                    }



                }

                @include breakpoint(desktop) {
                    margin-left: auto;
                    text-align: end;
                }

                p {
                    line-height: 2;
                    font-size: getRem(15);
                    font-weight: 100;

                    @include breakpoint(desktop) {
                        font-size: getRem(20);
                    }


                    a {
                        text-decoration: none;
                        color: white;

                        &:hover {
                            color: #B45C45;
                        }
                    }
                }
            }

        }

        &-social {
            img {
                width: getRem(40);
                height: getRem(40);
                padding: getRem(5) getRem(24) getRem(64) 0;
            }

            @include breakpoint(desktop) {
                text-align: end;


                a {
                    img {
                        height: getRem(48);
                        padding: getRem(5) getRem(0) getRem(24) getRem(24);
                        width: auto;
                    } 
                }

            }



        }

    }
}