@import "../../styles/index.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.home {
    background-image: url('https://cdn.atlasmagnetics.com/background.png');
    background-size: cover;
    background-color: rgba(0,0,0, 0.4)!important;
    

    &-introduction {
        position: relative;
        height: 100vh;
        .block-link{
            position: absolute;
            bottom: getRem(50);
            z-index: 100;
            right:getRem(25);
            color:white;
            text-decoration: none;
            font-size: getRem(16);
        }
        &-text {
            position: absolute;
            width: getRem(625);
            margin-top: getRem(256);
            padding: getRem(56) 0 getRem(40) getRem(190);
            background: rgba(22, 26, 35, 0.6);
            backdrop-filter: blur(10px);
            font-weight: 100;

            p {
                margin: 0;
                margin-bottom: getRem(48);
                width: getRem(500);
                font-size: getRem(48);
                font-family: "Montserrat-Bold";
                font-weight: 700;
                color: white;

                span {
                    margin: 0;
                    padding-top: getRem(23);
                    font-weight: 500;
                    font-size: getRem(32);
                    color: white;
                    font-family: "Montserrat-Light";
                    line-height: getRem(39);
                }
            }
        }

    }

    .video-box {
        
        margin-top: getRem(70);
        width: 100%;
        height: calc(100vh - getRem(70));
        position: absolute;
        top: 0;
        left: 0;
    }

    &-solving {
        height: getRem(464);
       
        
        .image-gallery{
            position: absolute;
            width: 100%;
            height: getRem(480);
            .image-gallery-slide .image-gallery-image{
                object-fit: cover;
                height: 100%;
                max-height: getRem(480);
            }
        }
        &-text {
            position: absolute;
            margin-top: getRem(64);
            right: 0;
            text-align: end;
            font-family: "Montserrat-Light";
            width: getRem(839);
            padding: getRem(80) getRem(112);
            background: rgba(22, 26, 35, 0.6);
            backdrop-filter: blur(10px);
            font-weight: 100;
            p {
                margin: 0;
                margin-bottom: getRem(48);
                font-weight: 500;
                font-size: getRem(36);
                color: white;
            }
        }
    }

    &-atlas {
        display: flex;

        img {
            width: getRem(948);
        }

        &-text {
            color: white;
            background: rgba(22, 26, 35, 0.6);
            opacity: 0.9;
            font-size: getRem(20);
            padding: getRem(80) getRem(184) 0 getRem(190);
            text-align: justify;
            align-items: center;
            font-weight: 100;
            font-family: "Montserrat-Light";
            span {
                display: flex;
                gap: getRem(25);
                margin-left: getRem(60);
                text-align: center;
                font-size: getRem(48);
                margin-bottom: getRem(40);
                font-family: "Montserrat-Bold";
                font-weight: 700;

                img {
                    width: getRem(76);
                }
            }
        }
    }

    &-approach {
        height: getRem(1192);

        &-text {
            position: absolute;
            width: getRem(625);
            margin-top: getRem(232);
            padding: getRem(80) 0 getRem(64) getRem(190);
            background: rgba(22, 26, 35, 0.6);
            backdrop-filter: blur(10px);
            color: white;


            h4 {
                font-size: getRem(48);
                margin: 0;
                margin-bottom: getRem(32);
                font-family: "Montserrat-Bold";
                font-weight: 700;
            }

            p {
                margin: 0;
                margin-bottom: getRem(64);
                width: getRem(420);
                font-family: "Montserrat-Light";
                font-weight: 700;
                font-size: getRem(20);
            }
        }

        img {
            margin-top: getRem(64);
            width: 100%;
        }
    }
}

.home-mobile {
    background-image: url('https://cdn.atlasmagnetics.com/background.png');
    background-size: cover;
    background-color: rgba(0,0,0, 0.4)!important;

    padding-top: getRem(60);
    &-back {
        width: 100%;
    }

    &-intro {
        
        background-color: #fff;
        padding: getRem(24);

        h4 {
            margin: 0;
            font-size: getRem(28);
            color: #18366C;
            line-height: 46px;
            font-weight: 700;
        }

        p {
            margin-bottom: getRem(36);
            color: #FFA886;
            font-size: getRem(20);
        }
    }

    &-solving {
        padding: getRem(72) 0 getRem(96) getRem(24);
        background-image: url('https://cdn.atlasmagnetics.com/laminate.png');
        object-fit: cover;
        background-repeat: round;

        &-text {
            padding: getRem(47) getRem(24);
            background: rgba(22, 26, 35, 0.6);
            backdrop-filter: blur(10px);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            font-weight: 600;
            font-size: getRem(24);
            line-height: getRem(32);
            text-align: right;

            color: #FFFFFF;
        }
    }

    &-atlas {
        display: flex;
        flex-direction: column;

        p {
            color: white;
            font-size: getRem(15);
            font-weight: 400;
            line-height: 180%;
            font-family: "Montserrat-Light";
            padding: getRem(24);
            margin: 0;
        }

        img {
            height: getRem(392);
            object-fit: cover;
        }

        span {
            background: white;
            font-weight: 700;
            font-size: getRem(35);
            line-height: getRem(46);
            text-align: right;
            color: #18366C;
            padding: getRem(56) getRem(36) getRem(16) getRem(24);

            img {
                position: absolute;
                margin-top: getRem(-20);
                margin-left: getRem(-20);
                height: getRem(36);
            }
        }
    }

    &-approach {
        h4 {
            background: white;
            padding: getRem(48) getRem(24) getRem(24);
            margin: 0;
            font-weight: 700;
            font-size: getRem(38);
            line-height: getRem(46);
            color: #18366C;
        }

        p {
            font-weight: 500;
            font-family: "Montserrat-Light";
            font-size: getRem(24);
            line-height: getRem(34);
            color: #FFFFFF;
            padding: getRem(24)
        }
    }

    &-contact {
        height: getRem(20);
        padding: getRem(51) 0 getRem(69) getRem(24);
        &-button {
            cursor: pointer;
            text-decoration: none;
            font-size: getRem(15);
            color: white;
            font-weight: 700;
            border-radius: getRem(4);
            padding: getRem(10) getRem(20);
            border: 0;
            background: linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            transition: .3s;
            border: getRem(1) solid #B45C45;
            &:hover{
                background: linear-gradient(306.29deg, #FFA886  0%, #B45C45 142.59%);
                border: getRem(1) solid #B45C45;
            }
        }
    }
}