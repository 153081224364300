@import "../../styles/index.scss";

.page-404 {
    width: 100%;
    height: getRem(550);
    background: url('https://cdn.atlasmagnetics.com/qualityBackground.png') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: 1000;
    color: white;

    &-text {
        text-align: center;
        padding: getRem(200) getRem(40) getRem(40);
        font-size: getRem(40);
        @include breakpoint(desktop) {
            padding: getRem(170) getRem(40) getRem(40);
            font-size: getRem(63);
        }

        

        p {
            font-size: getRem(101);
            color: #B45C45;
        }
    }
}