@import "../../styles/index.scss";
.benefits {
    padding: getRem(0) getRem(20) getRem(40) getRem(20);
    @include breakpoint(desktop) {
        padding: 0;
    }

    h1 {
        font-size: getRem(32);
        font-family: "Montserrat";
        font-weight: 400;
        margin-top: getRem(56);
        margin-bottom: getRem(32);
    }
    &-list {
        
        padding: 0;
        @include breakpoint(desktop) {
            img {
                height: getRem(48);
                width: getRem(48);
                margin-right: getRem(30);
                
            }
        }
        ul {
            margin-top: getRem(-200);
            @include breakpoint(desktop) {
                margin-top: 0;
            }
        }
        li{


            display: flex;
            align-items: center;
            font-size: getRem(20);
            margin-bottom: getRem(25);
        }
        &-text{
            margin-left: getRem(20)!important;
            font-size: getRem(20)!important;
            font-weight: 100;
            font-family: "Montserrat-Light";
            max-width: getRem(250);
        }
    }
}
