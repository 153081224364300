@import '../../styles/index.scss';

.asic {
    &-header {
        background-image: url('https://cdn.atlasmagnetics.com/uasic.png');
        background-size: cover;
        background-position: 0% 0% 10% 0%;
        height: getRem(392);
        font-size: getRem(28);
        color: #FFFFFF;
        text-align: end;
        @include breakpoint(desktop) {
            height: getRem(440);
            font-size: getRem(48);
        }



        h2 {
            right: getRem(24);
            position: absolute;
            margin-top: getRem(137);

            @include breakpoint(desktop) {
                right: getRem(350);
                margin-top: getRem(192);
            }
        }
    }
    .paddingTopMobile{
        padding: getRem(0) getRem(24) getRem(72)!important;
        @include breakpoint(desktop) {
           
            padding: getRem(64) getRem(350) getRem(72)!important;
        } 
    }
    &-description {
        background-color: #fff;
        padding: getRem(48) getRem(24) getRem(72);
        @include breakpoint(desktop) {
            padding: getRem(64) getRem(350) getRem(72);
        }
        h4 {
            margin: 0;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: getRem(24);
            margin-bottom: getRem(40);
            @include breakpoint(desktop) {
                font-size: getRem(36);
            }
        }

        p {
            text-align: justify;
            color: black;
            font-size: getRem(15);
            font-weight: 400;
            line-height: 180%;
            font-family: "Montserrat-Light";
            @include breakpoint(desktop) {
                font-size: getRem(20);
                font-family: "Montserrat";

            }
        }&-list {
            list-style: none;
            padding: 0;
            img {
                height: getRem(40);
                width: getRem(40);
                margin-right: getRem(10);
            }
            li{ 
                display: flex;
                align-items: center;
                font-size: getRem(14);
                margin-bottom: getRem(25);
            }
            @include breakpoint(desktop) {
                img {
                    height: getRem(80);
                    width: getRem(80);
                    margin-right: getRem(10);
                }
                li{
                    display: flex;
                    align-items: center;
                    font-size: getRem(20);
                    margin-bottom: getRem(25);
                }
            }
           
            &-text{
                margin-left: getRem(8);
            }
        }
        &-btn{
            float: right;
            padding: getRem(3.5) getRem(20) getRem(2)!important;
            margin-bottom: getRem(20);
            display: flex;
            font-weight: 100!important;
            font-family: "Montserrat-Light";
            font-size: getRem(13)!important;
            align-items: center;
            img {
                
                padding-right: getRem(15);
                padding-left: 0;
                height: getRem(35);
                width: getRem(35);
            }
            @include breakpoint(desktop) {
                font-size: getRem(20)!important;
                margin-bottom: getRem(40);
                padding: getRem(3.5) getRem(20) getRem(2)!important;
                img {
                    padding-right: getRem(30);
                    padding-left:getRem(3);
                    height: getRem(40);
                    width: getRem(45);
                }

            }
            
        }

        &-first {
            margin-bottom: getRem(96);
        }
        &-box {
            overflow-x: scroll;
            @include breakpoint(desktop) {
                overflow-x:hidden
            }
        }
        &-table {
            border: none;
            &-header {
                height: getRem(80);
                color: #fff;
                border: none;
                background: url("../../assets/images/tableHeader.png") no-repeat center center; 
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
                @include breakpoint(desktop) {
                    height: getRem(120);
                }
                th {
                    color: "#FFF";
                    background: var(--midnight-80-gradient-10-blur, linear-gradient(180deg, rgba(22, 26, 35, 0.80) 0%, rgba(22, 26, 35, 0.00) 100%));
                    font-family: "Montserrat-Light";
                    font-size: getRem(10);
                    border: none;
                    text-align: center;
                    line-height: 120%;
                    max-width: getRem(50);
                    padding-left: getRem(15);
                    padding-right: getRem(15);
                    @include breakpoint(desktop) {
                        font-size: getRem(14);
                        line-height: 150%;
                    }
                }
            }
            tr:nth-child(even) {
                background: var(--gradient-2, linear-gradient(90deg, rgba(24, 54, 108, 0.20) 0%, rgba(24, 54, 108, 0.15) 0.01%, rgba(24, 54, 108, 0.05) 100%));
            }
           
           &-body{
            th{
                color: var(--173964-cobalt-blue, #18366C);
                text-align: center;
                font-family: "Montserrat-Light";
                font-size: getRem(12);
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                padding-top: getRem(10);
                padding-bottom: getRem(10);
                @include breakpoint(desktop) {
                    font-size: getRem(18);
                    line-height: 150%;
                }
                a {
                    font-size: getRem(12)!important;
                    text-decoration: none;
                    line-height: 1.5!important;

                    padding-right: getRem(5)!important;
                    color:#FFA886;
                    &:hover {
                        color:#B45C45;
                    }
                }

            }

            &-smallF {
                font-size: getRem(12)!important; 
                @include breakpoint(desktop) {
                    font-size: getRem(15)!important; 
                }
                
            }
            &-first {
                padding-left: getRem(10);
                background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: getRem(13)!important; 
                @include breakpoint(desktop) {
                    font-size: getRem(15)!important; 
                }
            }
           }
        }
    }
    &-images {
        background-image: url('https://cdn.atlasmagnetics.com/qualityBackground.png');
        background-size: cover;
        padding: getRem(48) getRem(24) getRem(72);
        img {
            display: none;
        }
        @include breakpoint(desktop) {
            display: flex;
            gap: getRem(76);
            padding: getRem(56) getRem(350)  getRem(80) getRem(260);
            align-items: center;

            img {
                width: getRem(668);
                height: getRem(400);
                display: inline;
            }
        }
        &-text {
            h4 {
                text-align: start;
                margin: 0;
                color: white;
                font-size: getRem(24);

                @include breakpoint(desktop) {
                    font-size: getRem(36);

                    background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            p {
                margin-top: 0;
                font-size: getRem(15);
                color: white;
                font-weight: 400;
                line-height: 180%;
                font-family: "Montserrat-Light";
                text-align: justify;
                @include breakpoint(desktop) {
                    font-size: getRem(20);
                    text-align: left;
                   
                    font-family: "Montserrat";
                }
            }
            img{
                display: inline;
                margin-top: getRem(24);
                width: 100%;
                padding-bottom: getRem(24);
                @include breakpoint(desktop) {
                    display: none;
                } 
            }
    
        }
    }
    &-supply {
        background: white;
        padding: getRem(48) getRem(24) getRem(72);
        img {
            display: none;
        }
        @include breakpoint(desktop) {
            padding: getRem(64) getRem(350) getRem(80);
            display: flex;
            gap: getRem(188);

            img {
                margin-top: getRem(120);
                display: inline;
                width: getRem(370);
                height: getRem(380);
            }
    
        }

        &-text {
            h4 {
                margin: 0;
                
                background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: getRem(24);

                @include breakpoint(desktop) {
                    font-size: getRem(36);
                }
            }
            p {
                margin-top: getRem(24);
                font-size: getRem(15);
                font-weight: 400;
                line-height: 180%;
                font-family: "Montserrat-Light";
                text-align: justify;
                @include breakpoint(desktop) {
                    font-size: getRem(20);
                    text-align: left;
                    
                    font-family: "Montserrat";
                }
            }
            img{
                display: inline;
                margin-top: getRem(24);
                width: getRem(240);
                margin-left: getRem(40);
                @include breakpoint(desktop) {
                    display: none;
                    margin-top: 0;
                    margin-left: 0;
                } 
            }
        }
    }
    &-production {
        padding: getRem(48) getRem(24) getRem(72);
        @include breakpoint(desktop) {
            padding: getRem(64) getRem(350) getRem(72);
        }
        color: white;
        background-image: url('https://cdn.atlasmagnetics.com/qualityBackground.png');
        h4 {
            margin: 0;
            color: white;
            margin-bottom: getRem(36);
            font-size: getRem(24);

            @include breakpoint(desktop) {
                font-size: getRem(36);
                background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        p {
            margin-top: 0;
            font-size: getRem(15);
            font-weight: 400;
            line-height: 180%;
            font-family: "Montserrat-Light";
            text-align: justify;
            @include breakpoint(desktop) {
                font-size: getRem(20);
                text-align: left;
                font-family: "Montserrat";
            }
        }


    }
}