@import "../../styles/index.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.contact {

    background: #161A23;
    z-index: 10;
    transition: 0.5s;
    margin-top: 0;

    @include breakpoint(mobile) {
        padding-top: getRem(36);
    }

    @include breakpoint(desktop) {
        padding-top: getRem(48);
        position: fixed;
        width: 100%;
    }

    &-status-box {

        padding: getRem(32) getRem(24) getRem(72);

        @include breakpoint(desktop) {
            padding: getRem(32) getRem(600) getRem(82);
        }

        &-text {
            text-align: center;
            color: white;
            margin-bottom: getRem(50);
            font-size: getRem(20);

            a {
                text-decoration: none;
                background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }

            img {
                height: getRem(70);
                margin-top: getRem(20);
            }

            @include breakpoint(desktop) {
                img {
                    height: getRem(100);

                    margin-top: getRem(50);
                }
            }


        }
    }

    &-form {
        padding: getRem(8) getRem(24) getRem(32);

        p {
            color: white;
            font-size: getRem(18);

        }

        .logic {
            display: flex;
            justify-content: space-between;

            svg {
                display: none;
            }
        }

        @include breakpoint(desktop) {
            padding: getRem(8) getRem(345) getRem(24) getRem(350);

            p {
                color: white;
                font-size: getRem(24);

            }

            .logic {
                svg {
                    color: #B45C45;
                    font-size: getRem(32);
                    padding: getRem(28) getRem(10) getRem(20);
                    cursor: pointer;
                    display: inline;
                }
            }
        }


        &-footer {
            @include breakpoint(desktop) {
                display: flex;
                justify-content: space-between;
                align-items: center;

                button {
                    margin-top: getRem(10);
                }
            }


            button {
                margin-top: getRem(30);
                transition: .3s;
                border: getRem(1) solid #B45C45;
                &:hover{
                    background: transparent;
                    border: getRem(1) solid #B45C45;
                }
            }
        }

        &-submit {
            transition: .3s;
            cursor: pointer;
            text-decoration: none;
            font-size: getRem(20);
            color: white;
            font-weight: 700;
            flex: end;
            padding: getRem(10) getRem(20);
            border-radius: getRem(4);
            background: linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            
           
        }

        &-video {
            margin-top: getRem(6);
            transition: opacity 500ms ease-in;

            input {
                color: white;
                width: 100%;

                @include breakpoint(desktop) {
                    width: getRem(572);
                }

            }

            .MuiFilledInput-input {
                padding: getRem(27) getRem(12) getRem(10);
            }

            &-date {

                @include breakpoint(desktop) {
                    gap: getRem(12);
                    display: flex;
                }

                .MuiFilledInput-input {
                    padding: getRem(27) getRem(12) getRem(10);
                }

                &-pick {
                    label {
                        margin: getRem(7) getRem(14);
                        font-family: "Montserrat-Light" !important;
                        font-weight: 500 !important;
                        font-size: 1.125rem !important;
                        color: white !important;
                    }

                    input {
                        margin: getRem(4) getRem(14);

                    }

                    .MuiInputBase-input {
                        padding: getRem(5) 0 getRem(7);
                    }

                    .MuiFormControl-root {
                        background-color: rgb(145 145 145 / 9%) !important;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                    }

                    label+.MuiInput-formControl {
                        margin-top: getRem(17);
                    }
                }

            }
        }

        &-toggle {
            margin-top: getRem(12);
            gap: getRem(20);
            display: flex;
            align-items: center;

            span {
                color: white;
            }
        }

        &-inputs {

            @include breakpoint(desktop) {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: getRem(12);
            }

            .email-input {
                flex: end;
            }

            &>* {
                flex: 1 1;
            }

            &-main {
                display: flex;
                flex-direction: column;

                .MuiFilledInput-input {
                    padding: getRem(27) getRem(12) getRem(10);
                }

                input {

                    color: white;

                }
            }

            label {
                color: white;
                font-size: getRem(20);
            }

            &-textarea {
                display: flex;
                flex-direction: column;

                textarea {
                    width: getRem(500);
                    height: getRem(170) !important;
                    color: white;

                }
            }

        }

    }

    &-header {
        display: flex;
        justify-content: end;
        align-items: center;
        padding: getRem(4) getRem(24);

        @include breakpoint(desktop) {
            padding: getRem(10) getRem(280);
            display: none;
        }


        svg {

            color: white;
            width: getRem(24);
            cursor: pointer;
            display: none;
        }
    }
}

.contact-page-p-desk-off {
    @include breakpoint(desktop) {
        padding-top: getRem(534);
    }
}

.contact-page-p-desk-on {
    @include breakpoint(desktop) {
        padding-top: getRem(454);
    }
}

.contact-page {

    &-header {
        background: #18366C;
        height: getRem(48);

        color: #FFFFFF;
        height: getRem(36);

        @include breakpoint(desktop) {
            height: getRem(48);
        }



        h2 {
            left: getRem(24);
            position: absolute;
            margin-top: getRem(4);
            font-size: getRem(24);

            @include breakpoint(desktop) {
                left: getRem(350);
                margin-top: getRem(4);
                font-size: getRem(30);
            }
        }
    }
    .emea{
        padding: getRem(10) getRem(20) getRem(0);
        font-size: getRem(36);
        background: linear-gradient(306.29deg, #b45c45 0%, #ffa886 142.59%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 600;
        @include breakpoint(tablet) {
            padding: getRem(10) getRem(294) getRem(0);
            font-size: getRem(36);
        }
        @include breakpoint(desktop) {
            padding: getRem(10) getRem(645) getRem(0);
            font-size: getRem(36);
        }
        
    }
    .tai-header{
        padding: getRem(10) getRem(20) getRem(0);
        font-size: getRem(36);
        background: linear-gradient(306.29deg, #b45c45 0%, #ffa886 142.59%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 600;
        @include breakpoint(tablet) {
            padding: getRem(10) getRem(294) getRem(0);
            font-size: getRem(36);
        }
        @include breakpoint(desktop) {
            padding: getRem(10) getRem(645) getRem(0);
            font-size: getRem(36);
        }
        
    }
    &-description {

        background-color: #fff;
            .first{
                padding: getRem(0) getRem(24) getRem(20);
                @include breakpoint(desktop) {
                    padding: getRem(18) getRem(240) getRem(48);
                }
            }
            .last{
                padding: getRem(10) getRem(24) getRem(72);
                @include breakpoint(desktop) {
                    padding: getRem(20) getRem(240) getRem(48);
                }
            }
            .last-block{
                padding: getRem(10) getRem(24) getRem(0);
                @include breakpoint(desktop) {
                    padding: getRem(20) getRem(240) getRem(48);
                }
            }
            .second-block{
                padding: getRem(10) getRem(24) getRem(64);
                @include breakpoint(desktop) {
                    padding: getRem(0) getRem(240) getRem(30);
                }
            }
            .second{
                padding: getRem(48) getRem(24) getRem(0);
                @include breakpoint(desktop) {
                    padding: getRem(0) getRem(240) getRem(100);
                }
            }
            .full-width{
                @include breakpoint(desktop) {
                    width: getRem(480)!important;
                }
                
            }

        .padding{
            @include breakpoint(desktop) {
                padding-bottom: getRem(16);
            }
            
        }

        .mailLink{
            color: #B45C45!important;
            text-decoration: none;
            &:hover{
                color: #cf7058!important;
            }
        }
        a {
            color: #18366C;
            font-weight: 500;

            &:hover {
                color: #355faf;
            }

            @include breakpoint(desktop) {
                font-size: getRem(20);
            }
        }

        @include breakpoint(desktop) {
            display: flex;
        }

        .west {
            padding: getRem(20) 0 getRem(5) 0;
            margin: getRem(0) 0 0 0;
            @include breakpoint(tablet) {
                margin: getRem(0) getRem(40) 0 0;
                
            }
            @include breakpoint(desktop) {

                margin: 0 getRem(60) 0 0;
                padding: getRem(0) 0 getRem(60) 0;
                img{
                    width: getRem(220)!important;
                    padding-bottom: getRem(14);
                }
            }


        }
        .cedar {
            padding: getRem(20) 0 getRem(5) 0;
            margin: getRem(0) 0 0 0;
            @include breakpoint(tablet) {
                margin: getRem(0) getRem(40) 0 0;
                
            }
            @include breakpoint(desktop) {

                margin: getRem(70) getRem(76) 0 0;
                padding: getRem(0) 0 getRem(48) 0;
                img{
                    width: getRem(220)!important;
                }
            }

        }
        .epc {
            padding: getRem(20) 0 getRem(5) 0;
            margin: getRem(0) 0 0 0;
            @include breakpoint(tablet) {
                margin: getRem(0) getRem(16) 0 0;
                
            }
            @include breakpoint(desktop) {

                margin: getRem(30) getRem(76) 0 0;
                padding: getRem(0) 0 getRem(48) 0;
                img{
                    width: getRem(220)!important;
                }
            }
        }
        .alltek {
            padding: getRem(0) 0 getRem(5) ;
            margin: getRem(0) 0 0 0;
            img{
                padding-bottom: getRem(14);
            }
            @include breakpoint(tablet) {
                margin: getRem(0) getRem(38) 0 0;
                
            }
            @include breakpoint(desktop) {

                margin: getRem(15) getRem(68) 0 0;
                padding: getRem(0) 0 getRem(48) 0;
                img{
                    width: getRem(220)!important;
                    padding-bottom: getRem(14);
                }
            }
        }


        &-contact-box {
            padding: getRem(20) getRem(0) getRem(0) getRem(0);
            @include breakpoint(tablet) {
                padding: getRem(0) getRem(24) getRem(0) getRem(24);
                display: flex;
                
            }
            @include breakpoint(desktop) {
                padding: getRem(0) getRem(82) getRem(0) getRem(110);
                display: flex;
                
            }


            .col {
                @include breakpoint(tablet) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-items: end;
                    
                }
                @include breakpoint(desktop) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-items: end;
                    
                }

                .box {
                    width: getRem(270);
                    padding: getRem(0) getRem(30) getRem(0) getRem(0);
                   
                    
                    h4 {
                        color: #18366C;
                        font-size: getRem(20);
                        line-height: getRem(16);
                        font-weight: 500;
                    }

                    p {
                        color: #161A23;
                        font-weight: 400;
                        font-size: getRem(15);
                        line-height: getRem(18);

                    }
                }
            }
        }
    }
}