@import '../../styles/index.scss';

.about {
    
    &-header {
        background-image: url('https://cdn.atlasmagnetics.com/about.png');
        background-size: cover;
        
        height: getRem(392);
        font-size: getRem(28);
        color: #FFFFFF;
        background-position: 80% 0%;
        @include breakpoint(desktop) {
            height: getRem(440);
            font-size: getRem(48);
        }




        h2 {
            right: getRem(24);
            position: absolute;
            margin-top: getRem(137);

            @include breakpoint(desktop) {
                right: getRem(350);
                margin-top: getRem(192);
            }
        }
    }

    &-description {
        background-color: #fff;
        padding: getRem(48) getRem(24) getRem(72);

        @include breakpoint(desktop) {
            padding: getRem(64) getRem(350) getRem(72);
        }

        h4 {
            margin: 0;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: getRem(24);
            margin-bottom: getRem(40);

            @include breakpoint(desktop) {
                font-size: getRem(36);
            }
        }

        p {
            margin-top: 0;
            font-size: getRem(15);
            font-weight: 400;
            line-height: 180%;
            font-family: "Montserrat-Light";
            text-align: justify;

            @include breakpoint(desktop) {
                font-size: getRem(20);
                text-align: left;
                font-family: "Montserrat";
            }
        }

        &-first {
            margin-bottom: getRem(96);
        }
    }

    &-images {
        background-image: url('https://cdn.atlasmagnetics.com/qualityBackground.png');
        background-size: cover;
        padding: getRem(48) getRem(24) getRem(72);

        img {
            width: 100%;
            display: none;
        }

        @include breakpoint(desktop) {
            padding: getRem(20) getRem(350) getRem(40) getRem(80);
            display: flex;
            align-items: center;
            gap: getRem(116);

            img {
                margin-top: getRem(100);
                width: getRem(773);
                height: 100%;
                display: inline;
            }
        }

        &-text {
            h4 {
                text-align: end;
                margin: 0;
                background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: getRem(24);

                @include breakpoint(desktop) {
                    margin-top: getRem(40);
                    font-size: getRem(36);
                    text-align: start;
                }


            }

            p {
                text-align: justify;
                color: white;
                font-size: getRem(15);
                font-weight: 400;
                line-height: 180%;
                font-family: "Montserrat-Light";
                text-align: justify;

                @include breakpoint(desktop) {
                    font-size: getRem(20);
                    font-family: "Montserrat";

                }


            }

            img {
                display: inline;

                @include breakpoint(desktop) {
                    display: none;
                }
            }
        }
    }

    &-supply {
        background: white;

        gap: getRem(116);
        padding: getRem(48) getRem(24) getRem(72);
        font-size: getRem(15);

        img {
            width: calc(100% + getRem(48));
            margin-left: getRem(-24);
            display: none;
        }

        @include breakpoint(desktop) {
            padding: getRem(64) getRem(350) getRem(96) getRem(350);
            display: flex;

            img {
                margin-top: getRem(50);
                display: inline;
                width: getRem(583);
                height: 100%;
            }
        }




        &-text {
            h4 {
                margin: 0;
                background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: getRem(24);

                @include breakpoint(desktop) {
                    font-size: getRem(36);
                }



            }

            img {
                display: inline;
                margin-top: getRem(40);

                @include breakpoint(desktop) {
                    display: none;
                }
            }

            p {
                text-align: justify;
                color: black;
                font-size: getRem(15);
                font-weight: 400;
                line-height: 180%;
                font-family: "Montserrat-Light";
                text-align: justify;

                @include breakpoint(desktop) {
                    font-size: getRem(20);
                    font-family: "Montserrat";
                }


            }
        }
    }
}