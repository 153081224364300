@import '../../styles/index.scss';

.articleUasic {
    &-description {
        background-color: #fff;
        padding: getRem(72) getRem(24) getRem(72);

        h4 {
            margin: 0;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: getRem(36);

        }

        hr {
            margin-bottom: getRem(40);
        }

        &-image {
            width: 100%
        }

        .href-link {
            text-decoration: none !important;
            color: #ca674e !important;

            &:hover {
                color: #B45C45 !important;
            }
        }

        &-btn {
            float: left;
            padding: getRem(3.5) getRem(20) getRem(2) !important;
            margin-bottom: getRem(20);
            display: flex;
            font-weight: 100 !important;
            font-family: "Montserrat-Light";
            font-size: getRem(13) !important;
            align-items: center;

            img {

                padding-right: getRem(15);
                padding-left: 0;
                height: getRem(35);
                width: getRem(35);
            }

            @include breakpoint(desktop) {
                font-size: getRem(20) !important;
                margin-bottom: getRem(40);
                padding: getRem(3.5) getRem(20) getRem(2) !important;

                img {
                    padding-right: getRem(30);
                    padding-left: getRem(3);
                    height: getRem(40);
                    width: getRem(45);
                }

            }

        }

        &-contact {
            background-color: #fff;
            padding: getRem(0) getRem(24) getRem(72);

            @include breakpoint(desktop) {
                padding: getRem(0) getRem(350) getRem(72);

            }

            p {
                text-align: justify;
                color: black;
                font-size: getRem(15);
                font-weight: 400;
                line-height: 180%;
                font-family: "Montserrat-Light";
                text-align: justify;

                @include breakpoint(desktop) {
                    font-size: getRem(20);
                    font-family: "Montserrat";
                }


            }
        }

        @include breakpoint(desktop) {
            padding: getRem(100) getRem(350) getRem(72);
        }

        .img-header {
            width: 100%;
            height: getRem(180);
            object-fit: cover;
            object-position: 20% 50%;

            @include breakpoint(desktop) {
                height: getRem(480);
                object-fit: cover;
                margin-bottom: getRem(-70);
                padding: getRem(24) getRem(0) getRem(24);

            }
        }

        h5 {
            margin: 0;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: getRem(28);

            @include breakpoint(desktop) {

                font-size: getRem(32);
            }
        }

        h6 {
            margin: 0;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: getRem(24);

            @include breakpoint(desktop) {

                font-size: getRem(28);
            }
        }

        h4 {
            margin: 0;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: getRem(32);

            @include breakpoint(desktop) {
                font-size: getRem(36);
                margin-top: getRem(83);
            }
        }

        p {
            text-align: justify;
            color: black;
            font-size: getRem(15);
            font-weight: 400;
            line-height: 180%;
            font-family: "Montserrat-Light";
            text-align: justify;

            @include breakpoint(desktop) {
                font-size: getRem(20);
                font-family: "Montserrat";
            }


        }

        ul {
            font-size: getRem(18);
            padding: 0 !important;

            line-height: 1.8;
            list-style: none;
        }

        li {
            font-size: getRem(16);
            font-family: "Montserrat-Light";

            @include breakpoint(desktop) {
                font-size: getRem(20);
                font-family: "Montserrat";
            }
        }

        &-last {
            display: flex;

            p {}
        }
    }
}