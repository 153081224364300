@font-face {
    font-family: 'Montserrat';
    src: url('../assets/fonts/Montserrat-Regular.ttf');
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat-Medium";
    src: url("../assets/fonts/Montserrat-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat-Light";
    src: url("../assets/fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat-Bold";
    src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}