@import "../../styles/index.scss";
.alert-dialog-flex {
    display: flex!important;
    align-items: flex-start;
}
.logout-btn {
    float: right!important;
    background: linear-gradient(306.29deg, #1c212c 0%, #18366C 142.59%)!important;
    border: 0.0625rem solid #18366C!important;
    &:hover {
        background: linear-gradient(306.29deg, #2d3546 0%, #214891 142.59%)!important;
    }

}
.close-btn {
    margin-right: getRem(50)!important;

}
.MuiPaper-root {
    background-color: #18366c !important;
    color: aliceblue!important;
}
#alert-dialog-description {
    color: aliceblue!important;
}