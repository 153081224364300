@import '../../styles/index.scss';

.edea {
    &-header {
        padding-top: getRem(60);

        
        font-size: getRem(28);
        color: #FFFFFF;
        text-align: end;
        @include breakpoint(desktop) {
            
        }



        h2 {
            right: getRem(24);
            position: absolute;
            margin-top: getRem(137);

            @include breakpoint(desktop) {
                right: getRem(350);
                margin-top: getRem(192);
            }
        }
    }
    &-description {
        background-color: #fff;
        padding: getRem(48) getRem(24) getRem(72);
        @include breakpoint(desktop) {
            padding: getRem(64) getRem(350) getRem(72);
        }
        h4 {
            margin: 0;
            background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: getRem(24);
            margin-bottom: getRem(40);
            @include breakpoint(desktop) {
                font-size: getRem(36);
            }
        }

        p {
            text-align: justify;
            color: black;
            font-size: getRem(15);
            font-weight: 400;
            line-height: 180%;
            font-family: "Montserrat-Light";
            @include breakpoint(desktop) {
                font-size: getRem(20);
                font-family: "Montserrat";

            }
        }&-list {
            list-style: none;
            padding: 0;
            img {
                height: getRem(80);
                width: getRem(80);
                margin-right: getRem(10);
            }
            li{
                display: flex;
                align-items: center;
                font-size: getRem(20);
                margin-bottom: getRem(0);
            }
            &-text{
                margin-left: getRem(8);
            }
        }
        &-btnDownload{
            font-size: getRem(11)!important;
            font-family: "Montserrat-Light";
            padding: getRem(1) getRem(20) getRem(1)!important;
            font-weight: 100;
            margin-top: getRem(32);
            display: flex;
            max-width: getRem(500);
            align-items: center!important;
            img {
                display: block!important;
                padding: getRem(10) getRem(14) getRem(5)!important;
                height: getRem(25)!important;
                width: getRem(25)!important;
                margin: getRem(-4) getRem(-2) getRem(2) !important;
                margin-left: auto!important;
            }
            @include breakpoint(desktop) {
                font-size: getRem(15)!important;
            }
        }
        &-getContact{
            font-size: getRem(12)!important;
            font-family: "Montserrat-Light";
            padding: getRem(12) getRem(20) getRem(12)!important;
            font-weight: 100;
            margin-top: getRem(32);
            @include breakpoint(desktop) {
                font-size: getRem(18)!important;
            }
            
             
        }

        &-first {
            margin-bottom: getRem(96);
        }
    }
    &-images {
        background-image: url('https://cdn.atlasmagnetics.com/qualityBackground.png');
        background-size: cover;
        padding: getRem(48) getRem(24) getRem(72);
        img {
            display: none;
        }
        @include breakpoint(desktop) {
            display: flex;
            gap: getRem(76);
            padding: getRem(56) getRem(250)  getRem(80) getRem(160);
            align-items: center;

            img {
                width: getRem(768);
                height: getRem(500);
                display: inline;
            }
        }
        &-text {
            h4 {
                text-align: start;
                margin: 0;
                color: white;
                font-size: getRem(24);

                @include breakpoint(desktop) {
                    font-size: getRem(36);

                    background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            p {
                margin-top: 0;
                font-size: getRem(15);
                color: white;
                font-weight: 400;
                line-height: 180%;
                font-family: "Montserrat-Light";
                text-align: justify;
                @include breakpoint(desktop) {
                    font-size: getRem(20);
                    text-align: left;
                   
                    font-family: "Montserrat";
                }
            }
            img{
                display: inline;
                margin-top: getRem(24);
                width: 100%;
                padding-bottom: getRem(24);
                @include breakpoint(desktop) {
                    display: none;
                } 
            }
    
        }
    }
    &-supply {
        background: white;
        padding: getRem(48) getRem(24) getRem(72);
        img {
            display: none;
        }
        @include breakpoint(desktop) {
            padding: getRem(64) getRem(350) getRem(80);
            display: flex;
            gap: getRem(188);

            img {
                margin-top: getRem(70);
                display: inline;
                width: getRem(370);
                height: getRem(380);
            }
    
        }

        &-text {
            h4 {
                margin: 0;
                
                background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: getRem(24);

                @include breakpoint(desktop) {
                    font-size: getRem(36);
                }
            }
            p {
                margin-top: getRem(24);
                font-size: getRem(15);
                font-weight: 400;
                line-height: 180%;
                font-family: "Montserrat-Light";
                text-align: justify;
                @include breakpoint(desktop) {
                    font-size: getRem(20);
                    text-align: left;
                    
                    font-family: "Montserrat";
                }
            }
            img{
                display: inline;
                margin-top: getRem(24);
                width: getRem(240);
                margin-left: getRem(40);
                @include breakpoint(desktop) {
                    display: none;
                    margin-top: 0;
                    margin-left: 0;
                } 
            }
        }
    }
    &-production {
        padding: getRem(48) getRem(24) getRem(72);
        @include breakpoint(desktop) {
            padding: getRem(64) getRem(350) getRem(72);
        }
        color: white;
        background-image: url('https://cdn.atlasmagnetics.com/qualityBackground.png');
        h4 {
            margin: 0;
            color: white;
            margin-bottom: getRem(36);
            font-size: getRem(24);

            @include breakpoint(desktop) {
                font-size: getRem(36);
                background: -webkit-linear-gradient(306.29deg, #B45C45 0%, #FFA886 142.59%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        p {
            margin-top: 0;
            font-size: getRem(15);
            font-weight: 400;
            line-height: 180%;
            font-family: "Montserrat-Light";
            text-align: justify;
            @include breakpoint(desktop) {
                font-size: getRem(20);
                text-align: left;
                font-family: "Montserrat";
            }
        }


    }
}