@import "../../styles/index.scss";

.download {
    z-index: 1000;
    color: white;
    background: url('https://cdn.atlasmagnetics.com/qualityBackground.png') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: getRem(80) getRem(20) getRem(20);
    @include breakpoint(desktop) {
        padding: getRem(280) getRem(500) getRem(280);
    }

    &-container {
        border-radius: 1px;
        background: var(--midnight-blur, rgba(22, 26, 35, 0.60));
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
        &-frame {
            display: none;
        }
        &-text {
            padding: getRem(20) getRem(20) getRem(10);
            @include breakpoint(desktop) {
                padding: getRem(20) getRem(125) getRem(10);
            }
            h1{
                font-size: getRem(32);
                font-family: "Montserrat";
                font-weight: 400;
            }
            p{
                font-size: getRem(18);
                font-family: "Montserrat-Light";
                font-weight: 100;
                display: flex;
                img {
                    display: none;
                    @include breakpoint(desktop) {
                        display: block;
                    }
                    position: absolute;
                    margin-left: getRem(600);
                    margin-top: getRem(-60);
                    width: getRem(128);
                    height: getRem(128);
                }
            }
            h5{
                font-size: getRem(10);
                font-family: "Montserrat-Light";
                font-weight: 100;
                color: #FFF;
                padding-top: getRem(30);
                a {
                    text-decoration: none;
                    color: #ca674e;
                    &:hover {
                        color: #B45C45;
                    }
                }
            }

        }
    }
}